import React from "react";
import mapboxgl from "!mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";

import {
    withMapboxStyleContext
} from "../../../../../context/MapboxStyleContext";
import getMapboxBounds from "../../../../../utilities/getMapboxBounds";

class RideEditor extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            longitude: 4.2,
            latitude: 52,
            zoom: 9
        };
        this.mapContainer = React.createRef();
        this.mapWaypoints = {};
        this.mapMarkers = {};
        this.mapParticipants = {};
        this.map = null;
        this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        this.onInteraction = this.onInteraction.bind(this);
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if(prevProps.hoveredWaypointId !== this.props.hoveredWaypointId) {
            if(prevProps.hoveredWaypointId) {
                this.setWaypointHovered(prevProps.hoveredWaypointId, false);
            }
            if(this.props.hoveredWaypointId) {
                this.setWaypointHovered(this.props.hoveredWaypointId, true);
            }
        }
        if(prevProps.hoveredMarkerId !== this.props.hoveredMarkerId) {
            if(prevProps.hoveredMarkerId) {
                this.setMarkerHovered(prevProps.hoveredMarkerId, false);
            }
            if(this.props.hoveredMarkerId) {
                this.setMarkerHovered(this.props.hoveredMarkerId, true);
            }
        }
        if(prevProps.geoJSON !== this.props.geoJSON && this.props.geoJSON) {
            this.drawLine(this.props.geoJSON);
        }
        if(prevProps.markersDraggable !== this.props.markersDraggable || prevProps.mode !== this.props.mode) {
            this.setMarkersDraggable(this.props.markersDraggable, this.props.mode);
        }
        if(prevProps.waypoints !== this.props.waypoints && this.props.waypoints) {
            this.updateWaypoints(this.props.waypoints);
        }
        if(prevProps.markers !== this.props.markers && this.props.markers) {
            this.updateMarkers(this.props.markers);
        }
        if(prevProps.participants !== this.props.participants && this.props.participants) {
            this.updateParticipants(this.props.participants);
        }
        if(prevProps.mapboxStyleContext.style.id !== this.props.mapboxStyleContext.style.id) {
            this.setMapStyle(this.props.mapboxStyleContext.style);
        }
    }

    setWaypointHovered(waypointId, hovered) {
        const marker = this.mapWaypoints[waypointId];
        if(!marker) {
            console.error("Waypoint " + waypointId + " doesn't have a marker in the map while trying to set it's hovered state.");
            return;
        }
        marker.setRotation(hovered ? 45 : 0);
    }

    setMarkerHovered(markerId, hovered) {
        const marker = this.mapMarkers[markerId];
        if(!marker) {
            console.error("Marker " + markerId + " doesn't have a marker in the map while trying to set it's hovered state.");
            return;
        }
        marker.setRotation(hovered ? 45 : 0);
    }

    componentDidMount() {
        const {
            longitude, latitude, zoom
        } = this.state;
        const {
            waypoints,
            markers,
            participants,
            geoJSON,
            mapboxStyleContext,
        } = this.props;
        this.map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: mapboxStyleContext.style.mapboxStyle,
            projection: "globe",
            center: [longitude, latitude],
            zoom
        });
        this.map.addControl(new MapboxLanguage({
            defaultLanguage: "nl"
        }));
        this.map.addControl(new mapboxgl.NavigationControl({
            visualizePitch: true
        }));
        this.map.on("load", () => {
            if(waypoints) {
                waypoints.forEach((waypoint) => {
                    this.addMarkerForWaypoint(waypoint);
                });
            }
            if(markers) {
                markers.forEach((marker) => {
                    this.addMarkerForMarker(marker);
                });
            }
            if(participants) {
                participants.forEach((participant) => {
                    this.addMarkerForParticipant(participant);
                });
            }
            if(waypoints || markers || participants) {
                this.fitAllMapMarkers();
            }
            if(geoJSON) {
                this.drawLine(geoJSON);
            }
        });
        this.map.on("move", () => {
            this.setState({
                longitude: this.map.getCenter().lng.toFixed(4),
                latitude: this.map.getCenter().lat.toFixed(4),
                zoom: this.map.getZoom().toFixed(2)
            });
        });
        this.map.on("mousedown", this.onInteraction);
        this.map.on("touchstart", this.onInteraction);
        this.map.on("wheel", this.onInteraction);
        this.map.on("dblclick", this.onInteraction);
        this.map.on("click", (event) => {
            this.onInteraction(event);
            this.onMapClick(event);
        });
    }

    setMapStyle(style) {
        this.map.setStyle(style.mapboxStyle, { diff: true });

        this.reloadGeoJSON(1000, 10);
    }

    reloadGeoJSON(delay = 0, retryCount = 0) {
        if(retryCount < 0) {
            return;
        }
        console.log(`Reloading GeoJSON (retryCount ${retryCount})`);
        const reload = () => {
            if(this.map.isStyleLoaded()) {
                this.drawLine(this.props.geoJSON);
                return;
            }
            this.reloadGeoJSON(1000, retryCount - 1);
        };
        if(delay === 0) {
            reload();
            return;
        }
        setTimeout(() => {
            reload();
        }, delay);
    }

    updateWaypoints(waypoints) {
        waypoints.forEach((waypoint) => {
            if(waypoint.id in this.mapWaypoints) {
                const mapMarker = this.mapWaypoints[waypoint.id];
                mapMarker.setLngLat([waypoint.longitude, waypoint.latitude]);
            } else {
                this.addMarkerForWaypoint(waypoint);
            }
        });
        Object.entries(this.mapWaypoints).forEach(([id, marker]) => {
            const waypointIndex = waypoints.findIndex((waypoint) => waypoint.id.toString() === id);
            if(waypointIndex === -1) {
                this.removeMarkerForWaypoint(id, marker);
            }
        });
        this.setState({ debugWaypoints: Object.keys(this.mapWaypoints) });
        if(this.props.autoFollow) {
            this.fitAllMapMarkers();
        }
    }

    updateMarkers(markers) {
        markers.forEach((marker) => {
            if(marker.id in this.mapMarkers) {
                const mapMarker = this.mapMarkers[marker.id];
                mapMarker.setLngLat([marker.longitude, marker.latitude]);
            } else {
                this.addMarkerForMarker(marker);
            }
        });
        Object.entries(this.mapMarkers).forEach(([id, mapMarker]) => {
            const markerIndex = markers.findIndex((searchMarker) => searchMarker.id.toString() === id);
            if(markerIndex === -1) {
                this.removeMarkerForMarker(id, mapMarker);
            }
        });
        this.setState({ debugMarkers: Object.keys(this.mapMarkers) });
        if(this.props.autoFollow) {
            this.fitAllMapMarkers();
        }
    }

    updateParticipants(participants) {
        participants.forEach((participant) => {
            if(participant.id in this.mapParticipants) {
                const mapMarker = this.mapParticipants[participant.id];
                mapMarker.setLngLat([participant.longitude, participant.latitude]);
            } else {
                this.addMarkerForParticipant(participant);
            }
        });
        Object.entries(this.mapParticipants).forEach(([id, mapMarker]) => {
            const participantIndex = participants.findIndex((searchParticipant) => searchParticipant.id.toString() === id);
            if(participantIndex === -1) {
                this.removeMarkerForParticipant(id, mapMarker);
            }
        });
        this.setState({ debugParticipants: Object.keys(this.mapParticipants) });
        if(this.props.autoFollow) {
            this.fitAllMapMarkers();
        }
    }

    addMarkerForWaypoint(waypoint) {
        const draggable = this.props.markersDraggable && this.props.mode === "route";
        let mapMarker = new mapboxgl.Marker({
            element: null,
            color: "#3FB1CE",
            draggable
        });
        mapMarker.setLngLat([waypoint.longitude, waypoint.latitude]);

        mapMarker.on("dragend", this.onMarkerDragEnd);

        mapMarker.waypointId = waypoint.id;

        mapMarker.addTo(this.map);
        this.mapWaypoints[waypoint.id] = mapMarker;
    }

    addMarkerForMarker(marker) {
        const draggable = this.props.markersDraggable && this.props.mode === "markers";
        let mapMarker = new mapboxgl.Marker({
            element: null,
            color: "#ce3f3f",
            draggable
        });
        mapMarker.setLngLat([marker.longitude, marker.latitude]);

        mapMarker.on("dragend", this.onMarkerDragEnd);

        mapMarker.markerId = marker.id;

        mapMarker.addTo(this.map);
        this.mapMarkers[marker.id] = mapMarker;
    }

    addMarkerForParticipant(participant) {
        if(this.mapParticipants[participant.id]) {
            this.removeMarkerForParticipant(participant.id, this.mapParticipants[participant.id]);
        }

        let mapMarker = new mapboxgl.Marker({
            element: null,
            color: "#ce3f3f",
            draggable: false
        });
        mapMarker.setLngLat([participant.longitude, participant.latitude]);

        mapMarker.participantId = participant.id;

        mapMarker.addTo(this.map);
        this.mapParticipants[participant.id] = mapMarker;
    }

    removeMarkerForWaypoint(id, marker) {
        marker.remove();
        delete this.mapWaypoints[id];
    }

    removeMarkerForMarker(id, marker) {
        marker.remove();
        delete this.mapMarkers[id];
    }

    removeMarkerForParticipant(id, marker) {
        marker.remove();
        delete this.mapParticipants[id];
    }

    fitAllMapMarkers() {
        const {
            waypoints,
            markers,
            participants,
        } = this.props;
        let allCoordinates = [];
        if(waypoints) {
            allCoordinates = [...waypoints];
        }
        if(markers) {
            allCoordinates = [...allCoordinates, ...markers];
        }
        if(participants) {
            allCoordinates = [...allCoordinates, ...participants];
        }
        if(allCoordinates.length === 0) {
            return;
        }
        const bounds = getMapboxBounds(allCoordinates);
        if(!bounds) {
            return;
        }
        this.map.fitBounds(bounds, {
            padding: 150,
            maxZoom: 14
        });
    }

    setMarkersDraggable(draggable, mode) {
        Object.values(this.mapWaypoints).forEach((marker) => {
            const isDraggable = draggable && mode === "route";
            marker.setDraggable(isDraggable);
        });
        Object.values(this.mapMarkers).forEach((marker) => {
            const isDraggable = draggable && mode === "markers";
            marker.setDraggable(isDraggable);
        });
    }

    onMapClick(event) {
        this.props.onMapClick?.(event.lngLat.lng, event.lngLat.lat);
    }

    onInteraction(_event) {
        this.props.onInteraction?.();
    }

    onMarkerDragEnd(event) {
        const marker = event.target;
        if(marker.waypointId === undefined && marker.markerId === undefined) {
            console.error("Marker without waypointId and markerId dragend.");
            return;
        }
        const coordinates = marker.getLngLat();

        if(marker.waypointId && this.props.onWaypointDragEnd) {
            this.props.onWaypointDragEnd(marker.waypointId, coordinates.lng, coordinates.lat);
        }
        if(marker.markerId && this.props.onMarkerDragEnd) {
            this.props.onMarkerDragEnd(marker.markerId, coordinates.lng, coordinates.lat);
        }
    }

    drawLine(geoJSON) {
        if(!this.map.isStyleLoaded()) {
            return;
        }
        const existingLayer = this.map.getLayer("route");
        if(existingLayer) {
            this.map.removeLayer("route");
        }
        const existingSource = this.map.getSource("route");
        if(existingSource) {
            this.map.removeSource("route");
        }
        if(!geoJSON.routes || geoJSON.routes.length === 0) {
            return;
        }
        const route = geoJSON.routes[0];
        this.map.addSource("route", {
            type: "geojson",
            data: {
                type: "Feature",
                properties: {},
                geometry: route.geometry
            }
        });
        this.map.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: {
                "line-join": "round",
                "line-cap": "round"
            },
            paint: {
                "line-color": "#0985cb",
                "line-width": 4
            }
        });
    }

    render() {
        const {
            longitude,
            latitude,
            zoom,
            debugWaypoints,
            debugMarkers
        } = this.state;
        const {
            style,
            debug
        } = this.props;
        return (
            <div className="w-100">
                { debug && (
                    <div className="mapbox-coordinates-debug">
                        Longitude: {longitude} | Latitude: {latitude} | Zoom: {zoom}
                        <br/>
                        Waypoints:<br/>
                        { debugWaypoints && debugWaypoints.map((waypoint) => (
                            <div key={ waypoint }>
                                { waypoint }
                            </div>
                        ))}
                        Markers:<br/>
                        { debugMarkers && debugMarkers.map((marker) => (
                            <div key={ marker }>
                                { marker }
                            </div>
                        ))}
                    </div>
                )}
                <div ref={ this.mapContainer } className="mapbox-map-container" style={ style }/>
            </div>
        );
    }
}
RideEditor.defaultProps = {
    markersDraggable: false,
    debug: false
};

export default withMapboxStyleContext(RideEditor);
